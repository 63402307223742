import React, { useEffect, useState } from 'react';
// Utils
import useWallet from 'use-wallet';
import Web3 from 'web3';
import CONTRACTS from 'service/abi.json';
import clsx from 'clsx';
import axios from 'axios';
// Components
import {
    Box,
    Container,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Hidden,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress,
    Grow,
    Grid,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
// Icons
import { Menu } from '@mui/icons-material';
import { AiOutlineTwitter } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';
import { FaDiscord, FaRoad, FaInstagram } from 'react-icons/fa';
import { RiCoinFill } from 'react-icons/ri';
import NotificationMessage from 'components/NotificationMessage';
// Images
import GGLogo from 'assets/logo.png';
import Marquee from 'react-fast-marquee';
import Dots from 'assets/dots.png';
import GG1 from 'assets/507.png';
import GG2 from 'assets/506.png';
import GG3 from 'assets/500.png';
import GG4 from 'assets/505.png';
import GG5 from 'assets/83.png';
import GG6 from 'assets/84.png';
import GG7 from 'assets/85.png';
import GG8 from 'assets/90.png';
import GG9 from 'assets/91.png';
import GG10 from 'assets/92.png';
import FooterLogo from 'assets/logo-written.png';
import Lottie from 'lottie-react';
import AnimationData from './animate-test.json';
// data
import selectionData from './data.json';
// Styles
import useStyles from './styles';

const images = [GG1, GG2, GG3, GG4, GG5, GG6, GG7, GG8, GG9, GG10];
const base = 'https://ey3aplwfs2.execute-api.us-east-1.amazonaws.com/Prod';
const routes = {
    galaktic: `/galaktic/`,
};

const links = [
    { name: 'Home', link: 'https://galakticgang.com', icon: MdDashboard },
    {
        name: 'Roadmap',
        link: 'https://galakticgang.com/#roadmap',
        icon: FaRoad,
    },
    {
        name: 'Utility',
        link: 'https://galakticgang.com/#utility',
        icon: RiCoinFill,
    },
];
const socials = [
    {
        name: 'Discord',
        link: 'https://discord.gg/galakticgang',
        icon: FaDiscord,
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/galakticgangnft',
        icon: FaInstagram,
    },
    {
        name: 'Twitter',
        link: 'https://www.twitter.com/galakticgangnft',
        icon: AiOutlineTwitter,
    },
];

export default function App() {
    const wallet = useWallet();
    const classes = useStyles();
    const refreshInterval = React.useRef(null);
    const [name, setName] = useState('');
    const [discordTag, setDiscordTag] = useState('');
    const [email, setEmail] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [drawer, setDrawer] = useState(false);
    const [contracts, setContracts] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [data, setData] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (wallet.ethereum) setupWeb3();
    }, [wallet.ethereum]);

    async function setupWeb3() {
        if (!wallet.ethereum) return;
        const _web3 = new Web3(wallet.ethereum);
        setWeb3(_web3);
    }

    useEffect(() => {
        if (web3) connect();

        // eslint-disable-next-line
    }, [web3, wallet.account]);

    useEffect(() => {
        if (contracts) {
            getData();
        }

        return () => clearInterval(refreshInterval.current);
    }, [contracts]);

    function connect() {
        const _contracts = {};

        _contracts.galaktic = new web3.eth.Contract(
            CONTRACTS.GalakticGang.ABI,
            CONTRACTS.GalakticGang.Address[wallet.chainId],
        );

        setContracts(_contracts);
    }

    const handleConnect = () => {
        if (wallet.status === 'connected') {
            return wallet.reset();
        }
        wallet.connect('injected');
    };

    const handleSubmit = async () => {
        if (!data.type) return errorToast('Account is not a winner.');

        setSubmitting(true);
        try {
            const msg = `Sign this message to confirm ownership of this wallet.`;
            const signature = await web3.currentProvider.request({
                method: 'personal_sign',
                params: [msg, wallet.account],
                from: wallet.account,
            });

            await axios.post(base + routes.galaktic, {
                pin: msg,
                signature,
                type: data.type,
                email,
                name,
                homeAddress,
                discordTag,
            });

            successToast('Submitted! A message will be sent to you shortly.');
        } catch (error) {
            errorToast(error.message);
            console.log(error);
        }
        setSubmitting(false);
    };

    async function getData() {
        if (!wallet.account) return;

        let verbage = '';
        let type = null;
        if (selectionData['55'].includes(wallet.account)) {
            verbage =
                'Your account is available for the every 55th print deal!';
            type = '55';
        } else if (selectionData['100'].includes(wallet.account)) {
            verbage = 'Your account was one of the first 100 unique!';
            type = '100';
        } else if (selectionData['555'].includes(wallet.account)) {
            verbage =
                'Your account is available for the every 555th print deal!';
            type = '555';
        } else if (selectionData['random'].includes(wallet.account)) {
            verbage = 'Your account was randomly selected to win a print!';
            type = 'random';
        } else {
            verbage = 'Unfortunately your address was not a selected winner';
        }

        setData({
            verbage,
            type,
        });
    }

    async function getRevertReason(txHash) {
        try {
            const tx = await web3.eth.getTransaction(txHash);
            var result = await web3.eth.call(tx, tx.blockNumber);

            result = result.startsWith('0x') ? result : `0x${result}`;

            if (result && result.substr(138)) {
                const reason = web3.utils.toAscii(result.substr(138));
                return reason;
            } else {
                return 'Cannot get reason - No return value';
            }
        } catch (error) {
            return error.message.split('{')[0];
        }
    }

    function successToast(message, link) {
        toast.success(<NotificationMessage title={message} link={link} />, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,

            draggable: true,
            progress: undefined,
        });
    }

    function errorToast(message, link) {
        toast.error(<NotificationMessage title={message} link={link} />, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,

            draggable: true,
            progress: undefined,
        });
    }

    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
            }}
            role="presentation"
            onClick={() => setDrawer(false)}
            onKeyDown={() => setDrawer(false)}
        >
            <div className={classes.drawerLogoDiv}>
                <img src={GGLogo} className={classes.drawerLogo} />
            </div>
            <Divider />
            <List>
                {links.map((link, index) => (
                    <ListItem button key={link.name}>
                        <ListItemIcon>
                            <link.icon />
                        </ListItemIcon>
                        <ListItemText primary={link.name} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {socials.map((social, index) => (
                    <ListItem button key={social.name}>
                        <ListItemIcon>
                            <social.icon />
                        </ListItemIcon>
                        <ListItemText primary={social.name} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div className={classes.root}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={classes.header}>
                <Hidden mdDown>
                    <img src={GGLogo} className={classes.logo} />
                    <div className={classes.links}>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#about"
                                >
                                    About
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#artwork"
                                >
                                    Artwork
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#utility"
                                >
                                    Utility
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#roadmap"
                                >
                                    Roadmap
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#team"
                                >
                                    Team
                                </a>
                            </li>
                            <li>
                                {socials.map((social, idx) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            key={`scidx-${idx}`}
                                            href={social.link}
                                        >
                                            <social.icon
                                                className={classes.socialIcon}
                                            />
                                        </a>
                                    );
                                })}
                            </li>
                        </ul>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <Button onClick={() => setDrawer(true)}>
                        <Menu />
                    </Button>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        anchor={'right'}
                        open={drawer}
                        onClose={() => setDrawer(false)}
                    >
                        {list('right')}
                    </Drawer>
                </Hidden>
            </div>
            <div className={classes.navClear} />
            <div className={classes.body}>
                <Grid container spacing={6}>
                    <Grid
                        item
                        sm={12}
                        md={6}
                        classes={{ root: classes.gridAuto }}
                    >
                        <Grow in={true}>
                            <div>
                                <div className={clsx(classes.card)}>
                                    <Typography
                                        variant="h4"
                                        className="title"
                                        sx={{ marginBottom: 2 }}
                                    >
                                        Authenticate your
                                        <br />
                                        Galaktic Gang address
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        In order to receive prints from the
                                        mint, you must authenticate your address
                                        with us so we can verify you are who you
                                        say you are!
                                    </Typography>

                                    <div className={classes.field}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Full name"
                                            variant="outlined"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className={classes.field}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Discord Tag"
                                            variant="outlined"
                                            value={discordTag}
                                            onChange={(e) =>
                                                setDiscordTag(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className={classes.field}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Email"
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className={classes.field}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Address to ship prints to. (City state country zip etc.)"
                                            variant="outlined"
                                            value={homeAddress}
                                            onChange={(e) =>
                                                setHomeAddress(e.target.value)
                                            }
                                        />
                                    </div>
                                    {wallet.status === 'connected' ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={submitting}
                                            onClick={handleSubmit}
                                        >
                                            {submitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.loader}
                                                />
                                            )}
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleConnect}
                                        >
                                            Connect Metamask
                                        </Button>
                                    )}
                                    {wallet.status === 'connected' && (
                                        <>
                                            <Typography
                                                variant="body1"
                                                sx={{ marginTop: 3 }}
                                            >
                                                Connected with:{' '}
                                                <a
                                                    href={`https://etherscan.io/address/${wallet.account}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="bold">
                                                        {wallet?.account?.shortenAddress?.()}
                                                    </span>
                                                </a>
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{ marginTop: 3 }}
                                            >
                                                <span className="bold">
                                                    {data?.verbage ?? ''}
                                                </span>
                                            </Typography>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Grow>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item sm={12} md={6} sx={{ marginBottom: 2 }}>
                            <Lottie animationData={AnimationData} />
                            {/* <div className={classes.images}>
                                <img src={Group10} />
                            </div> */}
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
            <Hidden mdUp>
                <Marquee gradient={false} className={classes.marquee}>
                    {images.map((image, idx) => {
                        return (
                            <img
                                key={`m-${idx}`}
                                src={image}
                                className={classes.image}
                            />
                        );
                    })}
                </Marquee>
            </Hidden>
            <Container maxWidth={'md'} sx={{ paddingBottom: 8 }}>
                <div className={classes.disclaimer}>
                    <Typography variant="subtitle2">
                        <span className="bold">DISCLAIMER: </span>This
                        information will not be used for any other purposes then
                        sending you your official Galaktic Gang prints!
                    </Typography>
                </div>
            </Container>
            <div className={classes.footer}>
                <img src={Dots} className={classes.dots} />
                <div>
                    <img src={FooterLogo} />
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        <span className={'underline'}>
                            5555 Positive Freaks n Weirdo
                        </span>{' '}
                        to be recruited
                    </Typography>
                    <Typography variant="subtitle" className={'underline'}>
                        @2021 Chris Dyer NFT. All Rights Reserved
                    </Typography>
                </div>
                <div className={classes.footerLinks}>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#about"
                            >
                                About
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#artwork"
                            >
                                Artwork
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#utility"
                            >
                                Utility
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#roadmap"
                            >
                                Roadmap
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#team"
                            >
                                Team
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
